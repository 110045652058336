import React from "react";

import ProfileHeader from "@/game-lol/components/ProfileHeader.jsx";
import { REGION_LIST } from "@/game-lol/constants/constants.mjs";
import Container from "@/shared/ContentContainer.jsx";
import {
  // MatchPageContainer,
  MatchStatContainer,
} from "@/shared/Match.style.jsx";
import { PerfGrid } from "@/shared/Performance.style.jsx";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

function ProfilePerformance() {
  const {
    // _searchParams,
    parameters: [region, name],
  } = useRoute((prev, next) => prev?.currentPath === next.currentPath);

  return (
    <ProfileLayout>
      <ProfileHeader region={region} name={name} />

      <Container>
        <MatchStatContainer>
          <PerfGrid>
            <PerformanceCard
              title={["common:stats.accuracy", "Accuracy"]}
              score={0.78}
            />
            <PerformanceCard
              title={["common:stats.headshotPercent", "Headshot %"]}
              score={0.62}
            />
            <PerformanceCard
              title={["lol:stats.championMastery", "Champion Mastery"]}
              score={0.51}
            />
          </PerfGrid>
        </MatchStatContainer>
      </Container>
    </ProfileLayout>
  );
}

export default ProfilePerformance;

export function meta([regionId, name]) {
  const region = REGION_LIST.find((region) => region.key === regionId);
  const userName = name.replace("-", " #");

  return {
    title: [
      "lol:meta.profile.title",
      "{{userName}}'s {{regionName}} League of Legends Performance Overview",
      { userName, regionName: region?.name ?? regionId },
    ],
    description: [
      "lol:meta.profile.description",
      "View {{userName}}'s League of Legends profile and see how they perform.",
      { userName, regionName: region?.name ?? regionId },
    ],
  };
}
